import React from 'react'
import Layout from '../../components/404'

const NotFoundPage = ():any => (
	<Layout>
		{/* <SEO title="404: Not found" /> */}
		<h1>NOT FOUND</h1>
		<p>ugh, the sadness.</p>
	</Layout>
)
export default NotFoundPage
